














































import { NeedTraining } from '@/api/interfaces/needtraining'
import { defineComponent } from '@vue/composition-api'
import { RawLocation } from 'vue-router'
import { mapActions, mapMutations } from 'vuex'

export default defineComponent({
  name: 'MExistingTrainee',
  props: {
    listExistingNeedTraining: {
      type: Array,
      required: true,
    },
    email: {
      type: String,
      required: false,
    },
  },
  data () {
    return {
      dialogExistingTrainee: false,
      existingNeedTrainingHeaders: [
        {
          text: this.$t('trainee.list.headers.lastName'),
          align: 'start',
          value: 'trainee.lastName',
          sortable: false,
        },
        {
          text: this.$t('trainee.list.headers.firstName'),
          value: 'trainee.firstName',
          sortable: false,
        },
        {
          text: this.$t('trainee.list.headers.phoneNumber'),
          value: 'trainee.phoneNumber',
          sortable: false,
        },
        {
          text: this.$t('trainee.list.headers.email'),
          value: 'trainee.email',
          sortable: false,
        },
        {
          text: this.$t('trainee.list.headers.company'),
          value: 'company.name',
          sortable: false,
        },
        {
          text: this.$t('trainee.list.headers.trainingType'),
          value: 'trainingType.name',
          sortable: false,
        },
        {
          text: this.$t('trainee.list.headers.date'),
          value: 'trainingSession.startAt',
          sortable: false,
        },
        {
          text: this.$t('trainee.list.headers.actions'),
          value: 'actions',
          sortable: false,
        },
      ],
    }
  },
  watch: {
    listExistingNeedTraining (val) {
      this.dialogExistingTrainee = (val.length > 0)
    },
  },
  methods: {
    ...mapMutations('navigation', {
      forceCancelSaveNextRoute: 'forceCancelSaveNextRoute',
    }),
    ...mapActions('needTrainingForm', {
      saveNeedTraining: 'save',
      resetNeedTraining: 'reset',
    }),
    async createNeedTraining (item: NeedTraining) {
      this.resetNeedTraining({ trainee: item.trainee, trainingType: (this.$route.params.trainingType ? `/api/training_types/${this.$route.params.trainingType}` : null) })
      const needTraining = await this.saveNeedTraining()
      const route: RawLocation = { name: 'NeedTraining Edit', params: { idNeedTraining: needTraining.id } }
      this.forceCancelSaveNextRoute(route)
      this.$router.push(route)
    },
    close () {
      this.dialogExistingTrainee = false
    },
  },
})

